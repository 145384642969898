<template>
    <div ref="container" class="container" :class="{ disabled }" tabindex="0" @keydown="handleKeyEvent">
        <v-card
            v-if="showAutoComplete && !hasAutoSelected && searchResult.length > 0"
            class="boxShadow auto-complete"
            outlined
        >
            <v-list class="pa-0" dense>
                <v-list-item-group ref="autoList" v-model="selectedIndex" class="auto-complete-list" tabindex="0">
                    <v-list-item
                        v-for="(template, index) in searchResult"
                        :key="template.id"
                        dense
                        class="auto-complete-item"
                        :class="{ selected: index === selectedIndex }"
                        @click="applyAutoComplete(template.id)"
                    >
                        <span v-dompurify-html="template.name"></span>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { templateTypes } from '@/enums/templates.enums';

    const DEFAULT_SEARCH_PARAMS = Object.freeze({
        page: 1,
        limit: 10,
        minSearch: 3,
        maxSearch: 20,
    });

    export default {
        name: 'TemplatesShortcut',
        props: {
            searchString: {
                type: String,
                required: false,
                default: '',
            },
            showAutoComplete: {
                type: Boolean,
                required: false,
                default: true,
            },
            hasAutoSelected: {
                type: Boolean,
                required: false,
                default: false,
            },
            templateCategoryId: {
                type: Number,
                required: true,
                default: 0,
            },
            context: {
                type: String,
                required: false,
                default: '',
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            queueId: {
                type: Number,
                required: false,
                default: null,
            },
        },
        data() {
            return {
                selectedIndex: -1,
                templateTypes,
                searchResult: [],
                searchTimeout: null,
                debouncer: null,
            };
        },
        computed: {
            ...mapState('Cases', ['selectedCase']),
            ...mapState('QueueManager', ['allQueues']),

            currentQueueId() {
                return this.queueId || this.selectedCase?.case?.queueId;
            },

            sanitizedSearchString() {
                return this.removeHTML(this.searchString);
            },
            isSearchStringValid() {
                const { length } = this.sanitizedSearchString;
                return (
                    length >= DEFAULT_SEARCH_PARAMS.minSearch &&
                    length <= DEFAULT_SEARCH_PARAMS.maxSearch &&
                    this.showAutoComplete
                );
            },
        },
        watch: {
            searchString() {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                    this.searchTemplates();
                }, 1000);
            },
        },
        beforeDestroy() {
            this.removeOutsideClickListener();
        },
        methods: {
            ...mapActions('Templates', ['suggestionSearch', 'getTemplateById', 'transformContentMergeTags']),
            /**
             * Searches for templates based on the sanitized search string and updates the search results.
             */
            async searchTemplates() {
                if (this.searchString.includes('<br>')) {
                    return;
                }

                if (!this.isSearchStringValid) {
                    this.searchResult = [];
                    return;
                }

                const selectedQueue = this.allQueues.find((queue) => queue.ID === this.currentQueueId);

                const payload = {
                    categoryId: this.templateCategoryId,
                    page: DEFAULT_SEARCH_PARAMS.page,
                    limit: DEFAULT_SEARCH_PARAMS.limit,
                    search: this.sanitizedSearchString,
                    language: selectedQueue?.Language,
                };

                const queueId = this.queueId || this.selectedCase?.case?.queueId;

                if (queueId) {
                    payload.queueIds = [queueId];
                }

                this.searchResult = await this.suggestionSearch(payload);

                this.$emit('updateSearchString', this.sanitizedSearchString);

                if (this.searchResult.length > 0) {
                    this.$refs.container.focus();
                    this.selectedIndex = 0;
                    this.addOutsideClickListener(); // Add listener for outside click
                }
            },

            removeHTML(content) {
                const div = document.createElement('div');
                div.innerHTML = content;
                return div.textContent || '';
            },
            async applyAutoComplete(templateId) {
                const templateRaw = await this.getTemplateById(templateId);

                if (!templateRaw) return;

                const template = await this.transformContentMergeTags({
                    template: templateRaw,
                    context: 'AUTO_SUGGESTIONS',
                });

                const { content, name } = template;

                const formattedContent = {
                    active: true,
                    content,
                    text: name,
                    value: templateId,
                };

                this.$emit('applyAutoComplete', formattedContent);
                this.searchResult = [];
                this.selectedIndex = -1;
                this.removeOutsideClickListener(); // Remove listener on selection
            },

            handleBlurAndFocus() {
                this.$refs.container.blur();
                this.selectedIndex = -1;
                if (this.context) {
                    const element = document.querySelector(`#${this.context}`);
                    if (element) {
                        element.focus();
                        this.searchResult = [];
                    }
                }
                this.$emit('exit');
            },

            handleKeyEvent(event) {
                if (!this.showAutoComplete || this.hasAutoSelected || this.searchResult.length === 0) return;

                switch (event.key) {
                    case 'ArrowDown': {
                        this.selectedIndex = (this.selectedIndex + 1) % this.searchResult.length;
                        if (this.selectedIndex === 0) {
                            this.handleBlurAndFocus();
                            break;
                        }
                        event.preventDefault();
                        break;
                    }
                    case 'ArrowUp': {
                        this.selectedIndex =
                            (this.selectedIndex - 1 + this.searchResult.length) % this.searchResult.length;
                        event.preventDefault();
                        break;
                    }
                    case 'Enter': {
                        this.applyAutoComplete(this.searchResult[this.selectedIndex]?.id);
                        event.preventDefault();
                        break;
                    }
                    case 'Tab': {
                        event.preventDefault();
                        break;
                    }
                    default: {
                        this.handleBlurAndFocus();
                        break;
                    }
                }
            },

            handleOutsideClick(event) {
                if (!this.$refs.container.contains(event.target)) {
                    this.searchResult = [];
                    this.selectedIndex = -1;
                    this.removeOutsideClickListener();
                }
            },

            addOutsideClickListener() {
                document.addEventListener('click', this.handleOutsideClick);
            },

            removeOutsideClickListener() {
                document.removeEventListener('click', this.handleOutsideClick);
            },
        },
    };
</script>

<style scoped lang="scss">
    .container {
        padding: 0;
        margin: 0;
        position: relative;
    }

    .auto-complete {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        background-color: white;
        z-index: 9999;
    }

    .auto-complete-list {
        max-height: 165px;
        overflow-y: auto;
        margin: 0;
        padding: 0;
    }

    .auto-complete-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: none;
    }

    .disabled {
        pointer-events: none;
    }
</style>
