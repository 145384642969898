import { Node, VueNodeViewRenderer } from '@tiptap/vue-2';
import { mergeAttributes, Extension } from '@tiptap/core';
import Image from '@tiptap/extension-image';
import Fontfamily from '@tiptap/extension-font-family';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Table from '@tiptap/extension-table';
import TipTapImageComponent from '../components/Global/TipTapImageComponent.vue';

export default class HardBreakEnter extends Extension {
    keys() {
        return {
            Enter: () => {
                this.options.hardBreakEnter();

                return true;
            },
        };
    }
}

// allows us to set image width
const CustomImage = Image.extend({
    name: 'customImage',
    addAttributes() {
        return {
            src: {
                default: '',
                renderHTML: (attributes) => {
                    return {
                        src: attributes.src,
                        ...(attributes['data-src'] ? { 'data-src': attributes['data-src'] } : attributes),
                    };
                },
            },
            'data-src': {
                default: null,
                renderHTML: (attributes) => {
                    return {
                        'data-src': attributes['data-src'],
                    };
                },
            },
            width: {
                renderHTML: ({ width }) => ({ width }),
            },

            height: {
                renderHTML: ({ height }) => ({ height }),
            },

            isDraggable: {
                default: true,
                renderHTML: () => {
                    return {};
                },
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
        };
    },
    addNodeView() {
        return VueNodeViewRenderer(TipTapImageComponent);
    },
    parseHTML() {
        return [
            {
                tag: 'img[src]',
                getAttrs: (dom) => {
                    return {
                        src: dom.getAttribute('src'),
                        'data-src': dom.dataset.src,
                        width: dom.getAttribute('width'),
                        height: dom.getAttribute('height'),
                        alt: dom.getAttribute('alt'),
                        title: dom.getAttribute('title'),
                    };
                },
            },
        ];
    },
    renderHTML({ node, HTMLAttributes }) {
        return [
            'img',
            {
                ...HTMLAttributes,
                src: node.attrs.src || node.attrs['data-src'], // Use data-src if src is missing
                'data-src': node.attrs['data-src'], // Ensure data-src is output
            },
        ];
    },
});

// allows us to set fontsize
const FontSize = Fontfamily.extend({
    name: 'fontSize',
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: (element) => element.style.fontSize?.replace(/["']+/g, ''),
                        renderHTML: (attributes) => {
                            if (!attributes.fontSize) {
                                return {};
                            }

                            return {
                                style: `font-size: ${attributes.fontSize}`,
                            };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setFontSize:
                (fontSize) =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontSize }).run();
                },
            unsetFontSize:
                () =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run();
                },
        };
    },
});

const Div = Node.create({
    name: 'div',
    addAttributes() {
        return {
            id: {
                default: null,
            },
        };
    },
    content: 'block*',
    group: 'block',
    defining: true,
    parseHTML() {
        return [{ tag: 'div' }];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});

const CustomTableHeader = TableHeader.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: 'border: 1px solid #ddd; ',
                parseHTML: (element) => {
                    let style = element.getAttribute('style') || '';
                    // Add a border if it doesn't exist
                    if (!style.includes('border:')) {
                        style += ' border: 1px solid #ddd;';
                    }
                    return style;
                },
                renderHTML: (attributes) => {
                    return {
                        style: attributes.style,
                    };
                },
            },
        };
    },
});

const CustomTableCell = TableCell.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: 'border: 1px solid #ddd; ',
                parseHTML: (element) => {
                    let style = element.getAttribute('style') || '';
                    // Add a border if it doesn't exist
                    if (!style.includes('border:')) {
                        style += ' border: 1px solid #ddd;';
                    }
                    return style;
                },
                renderHTML: (attributes) => {
                    return {
                        style: attributes.style,
                    };
                },
            },
        };
    },
});

const CustomTable = Table.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: 'border-collapse: collapse;',
                parseHTML: (element) => {
                    const style = element.getAttribute('style') || '';
                    // Add border-collapse if it doesn't exist
                    return style.includes('border-collapse') ? style : `border-collapse: collapse; ${style}`;
                },
                renderHTML: (attributes) => {
                    return {
                        style: attributes.style,
                    };
                },
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['table', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});

const tiptapOptions = {
    size: [6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
    fontFamily: [
        null,
        'Arial',
        'Avenir',
        'Calibri',
        'Cambria Math',
        'Franklin Gothic',
        'Rockwell',
        'Times New Roman',
        'Verdana',
    ],
    colorPalette: [
        ['#FFFFFF', '#808080', '#000000'],
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
    ],
};

function translateTitle(val) {
    switch (val) {
        case 'Bold': {
            return 'fontStyle.thickness';
        }

        case 'Italic': {
            return 'fontStyle.italic';
        }

        case 'Bullet List': {
            return 'fontStyle.bulletList';
        }

        case 'Ordered List': {
            return 'fontStyle.orderedList';
        }

        case 'Paragraph': {
            return 'fontStyle.paragraph';
        }

        case 'Heading 1': {
            return 'fontStyle.heading1';
        }

        case 'Heading 2': {
            return 'fontStyle.heading2';
        }

        case 'Heading 3': {
            return 'fontStyle.heading3';
        }

        case 'Code Block': {
            return 'fontStyle.codeBlock';
        }

        case 'Horizontal Rule': {
            return 'fontStyle.horizontalRule';
        }
        case 'Subscript': {
            return 'fontStyle.subscript';
        }
        case 'Superscript': {
            return 'fontStyle.superscript';
        }
        case 'Link': {
            return 'fontStyle.link';
        }
        case 'Remove link': {
            return 'fontStyle.removeLink';
        }

        case 'Image': {
            return 'fontStyle.image';
        }

        case 'Quote': {
            return 'fontStyle.quote';
        }

        case 'Code': {
            return 'fontStyle.code';
        }

        case 'Toggle Fullscreen': {
            return 'fontStyle.fullscreen';
        }

        case 'Undo': {
            return 'fontStyle.undo';
        }

        case 'Redo': {
            return 'fontStyle.redo';
        }

        case 'Clear Formatting': {
            return 'fontStyle.formatting';
        }

        case 'Blockquote': {
            return 'fontStyle.blockquote';
        }

        case 'Underline': {
            return 'fontStyle.underline';
        }

        case 'Strikethrough': {
            return 'fontStyle.strike';
        }
        case 'Strike': {
            return 'fontStyle.strike';
        }
        case 'Color': {
            return 'fontStyle.textColor';
        }
        case 'Size': {
            return 'fontStyle.fontSize';
        }
        case 'FontFamily': {
            return 'fontStyle.fontFamily';
        }
        default: {
            return 'notFound';
        }
    }
}

export {
    CustomImage,
    FontSize,
    Div,
    HardBreakEnter,
    tiptapOptions,
    translateTitle,
    CustomTableCell,
    CustomTableHeader,
    CustomTable,
};
